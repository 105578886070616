import React, { useState } from "react";
import { createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from "firebase/auth";
import { auth, db } from "../../config/firebase";
import Input from "@mui/joy/Input";
import { Button } from "@mui/joy";
import HeaderAuth from "../../components/Headers/HeaderAuth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import moment from "moment";
import { doc, setDoc } from "firebase/firestore";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

export default function Register() {
  const navigate = useNavigate();
  const { t } = useTranslation(); // Initialize useTranslation hook
  const [isLoading, setIsLoading] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [secondFirstName, setSecondFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [knowing, setKnowing] = useState(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verifPassword, setVerifPassword] = useState("");
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);

  const validate = async () => {
    if (!firstName || !lastName) {
      toast.info(t('error_missing_info')); // Use translation here
      setIsLoading(false);
      return;
    }

    if (!email) {
      toast.info(t('error_invalid_email')); // Use translation here
      setIsLoading(false);
      return;
    }

    if (!password || password.length < 4) {
      toast.info(t('error_weak_password')); // Use translation here
      setIsLoading(false);
      return;
    }

    if (password !== verifPassword) {
      toast.info(t('error_password_mismatch')); // Use translation here
      setIsLoading(false);
      return;
    }
    try {
      setIsLoading(true);
      const res = await createUserWithEmailAndPassword(auth, email, password);

      const myRef = doc(db, "users", res.user.uid);
      await setDoc(
        myRef,
        {
          firstName:
            secondFirstName === ""
              ? firstName
              : firstName + " " + secondFirstName,
          lastName: lastName,
          knowing: knowing,
          email: email,
          createdAt: moment().format(),
        },
        { merge: true }
      );
      await updateProfile(auth.currentUser, {
        displayName: firstName + " " + lastName,
      });
      sendEmailVerification(auth.currentUser);
      navigate("/");
    } catch (error) {
      switch (error.code) {
        case "auth/email-already-in-use":
          toast.error(t('error_email_in_use')); // Use translation here
          break;
        case "auth/invalid-email":
          toast.error(t('error_invalid_email_format')); // Use translation here
          break;
        case "auth/operation-not-allowed":
          toast.error(t('error_operation_not_allowed')); // Use translation here
          break;
        case "auth/weak-password":
          toast.error(t('error_weak_password_format')); // Use translation here
          break;
        default:
          toast.error(t('error_generic', { error: error.message })); // Use translation here
          break;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const options = [
    { value: "Facebook", label: "Facebook" },
    { value: "Instagram", label: "Instagram" },
    { value: "TikTok", label: "TikTok" },
    { value: "Television advertisement", label: "Television advertisement" },
    { value: "Word of mouth", label: "Word of mouth" },
  ];

  const handleChange = (selected) => {
    setKnowing(selected.value);
  };

  return (
    <div className="loginBg bg-gray-200 min-h-screen">
      <HeaderAuth transparent />
      <form className="mx-auto w-full p-5 bg-white max-w-xs md:max-w-md rounded-sm border border-gray-300">
        <h2 className="font-semibold text-2xl">{t('register_title')}</h2>
        <h4 className="mt-4 mb-2 text-base text-gray-500">
          {t('personal_info')}
        </h4>
        <div className="">
          <Input
            className="w-full"
            value={lastName}
            variant="outlined"
            placeholder={t('last_name_placeholder')}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
        </div>
        <div className="mt-2">
          <Input
            className="w-full"
            value={firstName}
            variant="outlined"
            placeholder={t('first_name_placeholder')}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
        </div>
        <div className="mt-2">
          <Input
            className="w-full"
            value={secondFirstName}
            variant="outlined"
            placeholder={t('second_first_name_placeholder')}
            onChange={(e) => {
              setSecondFirstName(e.target.value);
            }}
          />
        </div>
        <h4 className="mt-4 mb-2 text-base text-gray-500">
          {t('source_info')}
        </h4>
        <div className="">
          <Select onChange={handleChange} options={options} />
        </div>
        <h4 className="mt-4 mb-2 text-base text-gray-500">{t('email_placeholder')}</h4>
        <div className="">
          <Input
            className="w-full"
            placeholder={t('email_placeholder')}
            value={email}
            variant="outlined"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <h4 className="mt-4 mb-2 text-base text-gray-500">{t('password_placeholder')}</h4>
        <div className="">
          <Input
            className="w-full"
            placeholder={t('password_placeholder')}
            value={password}
            variant="outlined"
            type="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </div>

        <div className="mt-2">
          <Input
            className="w-full"
            placeholder={t('confirm_password_placeholder')}
            value={verifPassword}
            variant="outlined"
            type="password"
            onChange={(e) => {
              setVerifPassword(e.target.value);
            }}
          />
        </div>

        <div className="mt-4 flex items-center">
          <Button style={{ width: "100%" }} onClick={validate} loading={isLoading} variant="solid">
            {t('submit_button')}
          </Button>
        </div>
      </form>
    </div>
  );
}
