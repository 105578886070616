import React from "react";
import PageTemplate from "../templates/PageTemplate";
import { useNavigate } from "react-router-dom";
import { auth } from "../config/firebase";
import { sendEmailVerification } from "firebase/auth";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import HeaderAuth from "../components/Headers/HeaderAuth";
import HeaderHome from "../components/Headers/HeaderHome";
import { COLORS } from "../styles/colors";
import mockup from "../assets/img/Pixel-7a.png";
import mockup2 from "../assets/img/mockup2.png";
import mockup3 from "../assets/img/mockup3.png";
import playStore from "../assets/img/playStore.png";
import { Button } from "@mui/joy";
import Footer from "../components/Footer";

export default function Home() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <section className="bgHome w-full min-h-[90vh]">
        <HeaderHome transparent />
        <div className="py-10 mx-auto w-11/12 lg:w-5/6 flex flex-col lg:flex-row justify-between gap-5">
          <div className="lg:w-2/6">
            <h1
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                color: COLORS.primary,
              }}
              className="rounded-md p-2 font-bold text-white text-6xl"
            >
              {t("title_sur4plots")}
            </h1>
            <p
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.4)",
              }}
              className="text-white rounded-md p-2 font-regular mt-5 text-2xl"
            >
              {t("description_sur4plots")}
            </p>
            <div></div>
            {/* <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.sur4plots"
            >
              <img
                onClick={() => navigate("/")}
                src={playStore}
                className="w-1/3 lg:w-1/2 mt-4"
                alt="Logo"
              />
            </a> */}
            <div className="mt-4 flex gap-5 flex-col md:flex-row md:items-center">
              {auth?.currentUser ? (
                <Button
                  size="lg"
                  onClick={() => navigate("/dashboard")}
                  variant={"solid"}
                >
                  {t("menu_dashboard")}
                </Button>
              ) : (
                <Button
                  size="lg"
                  onClick={() => navigate("/connexion")}
                  variant={"solid"}
                >
                  {t("menu_login")}
                </Button>
              )}
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.sur4plots"
              >
                <Button
                  style={{ backgroundColor: "white" }}
                  size="lg"
                  variant={"outlined"}
                >
                  {t("downloadapp")}
                </Button>
              </a>
            </div>
          </div>
          <div className="lg:-mt-20 lg:w-6/12">
            <img
              onClick={() => navigate("/")}
              src={mockup}
              className=""
              alt="Logo"
            />
          </div>
        </div>
      </section>
      <section className="w-full">
        <div className="py-20 mx-auto w-11/12 lg:w-4/6 flex flex-col lg:flex-row justify-between items-center">
          <div className="lg:w-1/3">
            <h1
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                color: COLORS.primary,
              }}
              className="rounded-md p-2 font-bold text-white text-6xl"
            >
              {t("title_170_countries")}
            </h1>
            <p className="rounded-md p-2 font-regular mt-5 text-2xl">
              {t("description_170_countries")}
            </p>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.sur4plots"
            >
              <div className="mt-2 w-full">
                <Button size="lg" style={{ width: "100%" }} variant="solid">
                  {t("button_find_vehicle")}
                </Button>
              </div>
            </a>
          </div>
          <div className="lg:-mr-36 lg:w-2/3">
            <img
              onClick={() => navigate("/")}
              src={mockup2}
              className="w-full"
              alt="mockup2"
            />
          </div>
        </div>
      </section>
      <section className="w-full bg-blue-200">
        <div className="py-20 mx-auto w-11/12 lg:w-4/6 flex flex-col lg:flex-row justify-between items-center">
          <div className="lg:-ml-36 lg:w-4/6">
            <img
              onClick={() => navigate("/")}
              src={mockup3}
              className="w-full"
              alt="mockup2"
            />
          </div>
          <div className="lg:w-2/6">
            <h1
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                color: COLORS.primary,
              }}
              className="rounded-md p-2 font-bold text-white text-5xl"
            >
              {t("title_stay_informed")}
            </h1>
            <p className="rounded-md p-2 font-regular mt-5 text-2xl">
              {t("description_stay_informed")}
            </p>
            <a>
              <div className="mt-2 w-full">
                <Button
                  size="lg"
                  style={{ width: "100%" }}
                  onClick={() => navigate("/connexion")}
                  variant="solid"
                >
                  {t("button_start_adventure")}
                </Button>
              </div>
            </a>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
